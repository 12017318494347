import { computed, inject, ref } from 'vue'
import { useBrandConfigStore } from '../stores/brandConfig.js'
import { useContextConfigurationStore } from '../stores/contextConfiguration.js'

import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'

export function useConfiguration (shouldLoad, storeOverride) {
  const configurationStore = storeOverride ?? useContextConfigurationStore()
  const brandConfigStore = useBrandConfigStore()
  const resourceBase = inject('resourceBase', null)
  const brandConfigBaseProvider = () => ({})// inject('brandConfigBaseProvider', () => ({}), false)

  const { loadBrand = true, loadContext = true, mock: shouldLoadMock = true } = shouldLoad || {}

  const brandConfigBase = brandConfigBaseProvider()
  brandConfigStore.setBrandConfigBase(cloneDeep(brandConfigBase))

  const brandConfig = computed(() => ({
    ...brandConfigStore.mergedBrandConfig,
    get (key, fallback) {
      return brandConfigStore.getBrandConfigProperty({ key, fallback: get(brandConfigBase, key, fallback) })
    }
  }))

  const contextConfig = ref(configurationStore.configurations)

  const loadRequiredConfiguration = async () => {
    if (loadBrand) {
      await brandConfigStore.loadBrandConfiguration(resourceBase)
    }

    if (window.addScenarios && shouldLoadMock) {
      await window.addScenarios()
    }

    if (loadContext) {
      await configurationStore.loadContextConfiguration(brandConfig.value.backendSystem)
    }
  }

  const isFeatureInactive = (...args) => configurationStore.isFeatureInactive(...args)
  const isFeatureActive = (...args) => configurationStore.isFeatureActive(...args)
  const isFeatureDisabled = (...args) => configurationStore.isFeatureDisabled(...args)
  const getFeatureConfig = (...args) => configurationStore.getFeatureConfig(...args)

  const getContextConfig = (...args) => configurationStore.getConfig(...args)

  return { brandConfig, contextConfig, getContextConfig, loadRequiredConfiguration, isFeatureInactive, getFeatureConfig, isFeatureActive, isFeatureDisabled }
}
