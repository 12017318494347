import * as Sentry from '@sentry/vue'

const localStorageAutoRetryKey = 'chunkErrorAutoReload'

const getAutoRetryState = () => {
  try {
    return JSON.parse(localStorage.getItem(localStorageAutoRetryKey))
  } catch (e) {
    return null
  }
}

const setModuleKeyAutoRetry = (moduleKey, autoRetry) => {
  const currentValue = getAutoRetryState() ?? {}

  if (autoRetry) {
    currentValue[moduleKey] = autoRetry
  } else {
    delete currentValue[moduleKey]
  }

  if (Object.keys(currentValue).length > 0) {
    localStorage.setItem(localStorageAutoRetryKey, JSON.stringify(currentValue))
  } else {
    localStorage.removeItem(localStorageAutoRetryKey)
  }
}

export const resilientImport = (webpackPromise, moduleKey, pushRoute = null) => {
  return webpackPromise
    .then(module => {
      setModuleKeyAutoRetry(moduleKey, null)
      return module
    })
    .catch(e => {
      if ((getAutoRetryState()?.[moduleKey] ?? false) === true) {
        console.warn('Skipping auto reload on chunk load error because it was already executed once')
        setModuleKeyAutoRetry(moduleKey, null)
        return
      }
      Sentry.captureMessage('Automatically resolving chunk load problem', scope => {
        scope.setExtra('originalError', e.stack)
        scope.setExtra('pushRoute', pushRoute)
      })
      Sentry.close(0)
      setModuleKeyAutoRetry(moduleKey, true)
      if (pushRoute !== null && pushRoute !== undefined) {
        console.warn(`navigating to ${pushRoute}`)
        window.location.href = pushRoute
      } else {
        window.location.reload()
      }
    })
}
