<template>
  <router-view />
</template>
<script>
import { defineComponent, onMounted } from 'vue'
import { useQuasar } from 'quasar'
import { mergeMessages } from 'boot/i18n'
import { useConfiguration } from './composables/useConfiguration.pinia'
import { useCustomStylesheet } from '@wilken8/portal-ui-common/composables/useCustomStylesheet'
import { useCustomIcons } from '@wilken8/portal-ui-common/composables/useCustomIcons'

export default defineComponent({
  name: 'App',
  setup () {
    const $q = useQuasar()
    const { brandConfig } = useConfiguration()
    const { iconMapFn } = useCustomIcons({ config: brandConfig.value, loadIcons: true })

    useCustomStylesheet({
      href: '/__publicPath__/resources/css/style.css',
      onloadFn: () => window.onResourcesLoaded()
    })

    $q.iconMapFn = iconMapFn

    onMounted(() => {
      const tenantI18n = brandConfig.value.i18n || {}
      mergeMessages(tenantI18n)
    })
  }
})
</script>
