import { resilientImport } from 'src/helpers/routeHelper'

const routes = [
  {
    path: '/',
    component: () => resilientImport(import('layouts/PmrLayout.vue'), 'pmrLayout'),
    children: [
      { path: '', component: () => resilientImport(import('pages/Index.vue'), 'pmrIndex') }
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => resilientImport(import('pages/Error404.vue'), 'error404')
  }
]

export default routes
