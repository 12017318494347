import { ref } from 'vue'
import { defineStore } from 'pinia'
import get from 'lodash/get'

export const useContextConfigurationStore = defineStore('contextConfiguration', () => {
  const loaded = ref(false)
  const configurations = ref({})

  const getFeatureConfig = (key) => {
    return configurations.value?.features?.[key]
  }

  const isFeatureActive = (key) => {
    const featureState = getFeatureConfig(key)?.state ?? 'ACTIVE'
    return featureState === 'ACTIVE'
  }

  const isFeatureInactive = key => {
    return getFeatureConfig(key)?.state === 'INACTIVE'
  }

  const isFeatureDisabled = key => {
    return getFeatureConfig(key)?.state === 'DISABLED'
  }

  const getConfig = key => {
    return get(configurations.value, key)
  }

  function loadContextConfiguration (backendSystem) {
    if (!loaded.value) {
      return fetch(`/__publicPath__/api/configuration?backend-system=${backendSystem}`)
        .then(response => response.json())
        .then(cfg => {
          setConfiguration(cfg)
        })
    } else {
      return Promise.resolve()
    }
  }

  function setConfiguration (configuration) {
    Object.keys(configuration).forEach(key => {
      configurations.value[key] = configuration[key]
    })
    if (get(configurations.value, 'contractFields.displayNumber') === false) {
      document.body.classList.add('hide-contract-number')
    }
    loaded.value = true
  }

  return {
    configurations,
    getConfig,
    getFeatureConfig,
    isFeatureActive,
    isFeatureDisabled,
    isFeatureInactive,
    loadContextConfiguration,
    setConfiguration
  }
})
