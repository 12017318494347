import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import { setCurrentErrorPage } from 'boot/errorHandler'
import featureHelper from 'helpers/featureHelper'
import { useConfiguration } from 'src/composables/useConfiguration.pinia'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function (/* { store, ssrContext } */) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.MODE === 'ssr' ? undefined : process.env.VUE_ROUTER_BASE)
  })

  Router.beforeEach((to, from, next) => {
    const { isFeatureInactive, getFeatureConfig } = useConfiguration()
    setCurrentErrorPage(null)
    const isPortalInactive = isFeatureInactive('active')
    if (isPortalInactive) {
      const messageOverride = getFeatureConfig('active')?.message
      setCurrentErrorPage({
        name: 'InactiveFeature',
        props: {
          icon: featureHelper.featureIcon('active'),
          title: featureHelper.featureInactiveTitle('active'),
          message: messageOverride || featureHelper.featureInactiveMessage('active'),
          showBackButton: false
        }
      })
    }
    next()
  })

  return Router
})
