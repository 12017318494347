import sanitizeHtml from 'sanitize-html'

export default ({ app }) => {
  app.config.globalProperties.$sanitize = (v) =>
    sanitizeHtml(v, {
      allowedTags: [
        'b',
        'br',
        'i',
        'u',
        'strike',
        'em',
        'strong',
        'a',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'div'
      ],
      allowedAttributes: {
        '*': ['style', 'class'],
        a: ['href', 'target']
      }
    })
}
