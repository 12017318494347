import { useConfiguration } from 'src/composables/useConfiguration.pinia'
export default async ({ app }) => {
  const { loadRequiredConfiguration } = useConfiguration({ contextConfig: false })
  await loadRequiredConfiguration()

  /* const fetchConfig = fetch('resources/config.json')
    .then(data => data.json())

  const config = await fetchConfig
  delete config.mock

  app.config.globalProperties.$tenantConfig = {
    get (prop, defaultValue) {
      return getOr(defaultValue, prop, this)
    },
    ...config
  }

  cfg = app.config.globalProperties.$tenantConfig
  app.provide('brandConfig', cfg)

  // Meterreading configuration
  // TODO: replace with control app
  cfg.meterReading = {
    enterDecimals: false,
    enterDecimalsPerEnergyType: [
      'NATURAL_GAS'
    ],
    reasons: [
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'WATER',
          'WASTE_WATER',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Änderung der Personenzahl im Haushalt',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Zusätzliche Dämmung',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Zusätzliche Feuerstelle (z.B. Kaminofen)',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'WATER',
          'WASTE_WATER',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Leerstand der Immobilie',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'WATER',
          'WASTE_WATER',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Umbauarbeiten',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Heizungsmodernisierung',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY'
        ],
        system: true,
        text: 'Eigenverbrauch Photovoltaikanlage',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Einbau Solaranlage',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY',
          'NATURAL_GAS',
          'WATER',
          'WASTE_WATER',
          'DISTRICT_HEATING'
        ],
        system: true,
        text: 'Preisänderung',
        visible: true
      },
      {
        forTypes: [
          'ELECTRICITY'
        ],
        system: true,
        text: 'Anschaffung stromintensiver Geräte',
        visible: true
      }
    ]
  } */
}
