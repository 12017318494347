import { ref } from 'vue'
import { defineStore } from 'pinia'
import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import deepmerge from 'deepmerge'

export const useBrandConfigStore = defineStore('brandconfig', () => {
  const mergeConfig = {
    arrayMerge (target, source) {
      if (target !== null && target?.length !== 0) {
        return target
      }
      return source
    }
  }

  const loaded = ref(false)
  const brandConfig = ref({})
  const brandConfigBase = ref({})
  const mergedBrandConfig = ref({})

  const getBrandConfigProperty = ({ key, fallback }) => get(mergedBrandConfig.value, key, fallback)

  function setBrandConfigBase (base) {
    brandConfigBase.value = base
    mergedBrandConfig.value = deepmerge(base, cloneDeep(brandConfig.value), mergeConfig)
    // Object.keys(state.mergedBrandConfig).forEach(k => ())
    // Object.assign(state.mergedBrandConfig, deepmerge(base, state.brandConfig, mergeConfig))
  }

  function setBrandConfig (config) {
    brandConfig.value = config
    mergedBrandConfig.value = deepmerge(brandConfigBase.value, brandConfig.value, mergeConfig)
    loaded.value = true
  }

  async function loadBrandConfiguration (resourceBase) {
    if (loaded.value) {
      return Promise.resolve()
    }

    if (!resourceBase) {
      resourceBase = 'resources'
    }

    resourceBase = resourceBase.replace(/\/$/, '')

    const configPath = `${resourceBase}/config.json`

    const res = await fetch(configPath)
    const cfg = await res.json()
    return setBrandConfig(cfg)
  }

  return {
    brandConfig,
    brandConfigBase,
    getBrandConfigProperty,
    loaded,
    loadBrandConfiguration,
    mergedBrandConfig,
    setBrandConfig,
    setBrandConfigBase
  }
})
