import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core'
import { ApolloProvider } from '@vue/apollo-option'
import { provideApolloClient } from '@vue/apollo-composable'

// HTTP connection to the API
const httpLink = createHttpLink({
  uri: 'graphql',
  fetch: (...args) => window.fetch(...args)
})

// Cache implementation
const cache = new InMemoryCache({
  typePolicies: {
    /* Type policies when data is requested multiple times */
  }
})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache
})

const apolloProvider = new ApolloProvider({
  defaultClient: apolloClient
})

export default ({ app }) => {
  provideApolloClient(apolloClient)
  app.use(apolloProvider)
}
