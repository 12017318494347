export default {

  handlers: {},

  localHandlers: null,

  app: null,

  install (app, options) {
    this.app = app

    if ('handlers' in options) {
      this.handlers = options.handlers
    }

    this._registerHandlers()
    app.config.globalProperties.$errorHandler = this
  },

  setLocalHandlers (localHandlers) {
    this.localHandlers = localHandlers
  },

  unsetLocalHandlers () {
    this.localHandlers = null
  },

  _searchHandler (errorType, handlerGroup) {
    let localHandlerGroup = this.localHandlers
    let globalHandlerGroup = this.handlers

    if (handlerGroup && localHandlerGroup) {
      localHandlerGroup = this.localHandlers[handlerGroup]
    }

    if (handlerGroup && globalHandlerGroup) {
      globalHandlerGroup = this.handlers[handlerGroup]
    }

    let localHandler
    let globalHandler
    if (localHandlerGroup) {
      const key = Object.keys(localHandlerGroup).find(k => new RegExp(k).test(errorType)) || 'default'
      localHandler = localHandlerGroup[key]
    }

    if (globalHandlerGroup) {
      const key = Object.keys(globalHandlerGroup).find(k => new RegExp(k).test(errorType)) || 'default'
      globalHandler = globalHandlerGroup[key]
    }

    return { localHandler, globalHandler }
  },

  _registerHandlers () {
    this._registerGlobalHandler()
    this._registerVueHandler()
    this._registerAxiosHandler()
  },

  _registerGlobalHandler () {
    window.addEventListener('error', err => this._callHandler('global', 'default', err))
  },

  _registerVueHandler () {
    this.app.config.errorHandler = err => {
      this._callHandler('vue', 'default', err)
    }
  },

  _registerAxiosHandler () {
    this.app.config.globalProperties.$axios.interceptors.response.use(
      response => response,
      err => {
        const errorCriteria = err.response ? err.response.status.toString() : err.toString()
        this._callHandler('axios', errorCriteria, err)
        return Promise.reject(err)
      })
  },

  _callHandler (handlerGroup, handlerCriteria, error) {
    const { localHandler, globalHandler } = this._searchHandler(handlerCriteria, handlerGroup)

    if (localHandler) {
      localHandler(error, globalHandler)
    } else if (globalHandler) {
      globalHandler(error)
    }
  }
}
