export default {
  'en-US': {
    monthyear: {
      year: '2-digit', month: '2-digit'
    },
    daymonth: {
      month: '2-digit', day: '2-digit'
    },
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    medium: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric'
    },
    longdate: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    }
  },
  'de-de': {
    monthyear: {
      year: '2-digit', month: 'short'
    },
    daymonth: {
      month: '2-digit', day: '2-digit'
    },
    short: {
      year: 'numeric', month: '2-digit', day: '2-digit'
    },
    medium: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    },
    longdate: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short'
    }
  }
}
