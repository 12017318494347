import { ref } from 'vue'
import ErrorHandler from '../helpers/errorHandler'
import * as Sentry from '@sentry/vue'

const op = ref({
  current: null
})
let handlers = {}

export default ({ app }) => {
  app.config.globalProperties.$errorPage = op
  handlers = {
    axios: {
      '5..': (e) => {
        Sentry.captureException(e)
        op.value.current = 'ServerError'
      },
      default: e => {
        console.error(e)
      }
    },
    vue: {
      default: (err) => {
        console.warn(err)
        op.value.current = 'GenericError'
      }
    },
    graphql: ({ operation, networkError, graphqlErrors, response }) => {
      if (networkError && networkError.statusCode === 401) {
        return
      }

      Sentry.captureException(new Error('Graphql query failed'), scope => {
        scope.setExtras({
          networkError: JSON.stringify(networkError),
          graphqlErrors: JSON.stringify(graphqlErrors),
          responseErrors: JSON.stringify(response),
          query: JSON.stringify({
            query: operation?.query?.loc?.source?.body,
            mutation: operation?.mutation?.loc?.source?.body,
            variables: operation?.variables
          })
        })
      })

      if (operation.getContext().skipGlobalErrorHandling) {
        return
      }

      if (networkError && networkError.statusCode >= 500 && networkError.statusCode <= 599) {
        op.value.current = 'ServerError'
      } else {
        op.value.current = 'GenericError'
      }
    },
    global: {
      default: (err) => {
        if (err.message !== 'ResizeObserver loop limit exceeded' && err.message !== 'Uncaught EvalError: Possible side-effect in debug-evaluate') {
          console.warn(err)
        }
      }
    }
  }
  app.use(ErrorHandler, { handlers })
}

export { handlers, op as errorPage }
export function setCurrentErrorPage (v) {
  op.value.current = v
}
