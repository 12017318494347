import { boot } from 'quasar/wrappers'
import { createI18n } from 'vue-i18n'
import messages from 'src/i18n'
import datetimeFormats from 'src/i18n/dateTime'
import deepmerge from 'deepmerge'

const i18n = createI18n({
  locale: 'de-de',
  messages,
  datetimeFormats,
  legacy: false
})

/* const loadLocalCustomMessages = () => {
  const tenantId = '__tenantId__'
  return import(`custom/${tenantId}/i18n`)
    .then(messages => messages.default)
    .catch(() => { return {} })
} */

const mergeMessages = toMerge => {
  Object.entries(toMerge).forEach(([locale, messagesToMerge]) => {
    i18n.global.setLocaleMessage(locale, deepmerge(messages[locale] || {}, messagesToMerge))
  })
}

export default boot(async ({ app }) => {
  // Set i18n instance on app
  // const localCustomMessages = await loadLocalCustomMessages()
  // mergeMessages(localCustomMessages)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())
  if (params.i18n_debug != null) {
    const originalT = i18n.global.t
    switch (params.i18n_mode) {
      case 'replace':
        i18n.global.t = (key, locale, values) => {
          if (i18n.global.te(key)) {
            const translatedValue = originalT(key, locale, values)
            return (translatedValue || '').replace(/\S/gm, 'X')
          } else {
            return '{{ not found }}'
          }
        }
        break
      case 'value':
      default:
        i18n.global.t = (key, _locale, values) => {
          if (values == null && (Array.isArray(_locale) || typeof _locale === 'object')) {
            values = _locale
          }
          return '{' + key + (values ? ' | ' + JSON.stringify(values) : '') + '}'
        }
        break
    }
  }
  app.use(i18n)
})

const globalI18n = i18n.global

export { globalI18n as i18n, mergeMessages }
