import { i18n } from 'boot/i18n'

export default {
  featureIcon (key) {
    switch (key) {
      case 'active':
        return 'app:reading-not-available'
    }
  },
  featureInactiveTitle (key) {
    const featureKey = `features.${key}.inactive.title`
    if (i18n.te(featureKey)) {
      return i18n.t(featureKey)
    }

    return i18n.t('features.generic.inactive.title')
  },
  featureInactiveMessage (key) {
    const featureKey = `features.${key}.inactive.message`

    if (i18n.te(featureKey)) {
      return i18n.t(featureKey)
    }

    return i18n.t('features.generic.inactive.message')
  }
}
